import * as React from "react"
import Layout from '../components/layout'


// markup
const Estudiantes = () => {
  return (
    <Layout>
      <div className="container-xl">
        <h1>Estudiantes</h1>
      </div>
    </Layout>
  )
}

export default Estudiantes
